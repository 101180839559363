<template>
  <div class="login_R_Form">
    <div class="login_R_FormItem flex_must">
      <div class="flex_must_1">单选框</div>
      必填&nbsp;<a-switch size="small" @change='giveF' v-model="checkData.options.require" />
    </div>
    <div class="login_R_FormItem">
      <div class="R_FormItem_name">组件名称</div>
      <div class="R_FormItem_data">
        <a-input @change='giveF' v-model="checkData.options.label" placeholder="请输入" :maxLength='20'
          :suffix="checkData.options.label?checkData.options.label.length+'/20':'0/20'" />
      </div>
    </div>
    <div class="login_R_FormItem">
      <div class="R_FormItem_name">提示文字</div>
      <div class="R_FormItem_data">
        <div @click="addItem" class="choiceImg_base_Noimg pointer">
          <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
          添加选项
        </div>

        <div v-for="(item,index) in checkData.options.optionsArray" :key="index" class="list_XX">
          <div class="list_XX_L">选项{{index+1}}：</div>
          <div class="list_XX_C"><a-input @change='giveF' v-model="checkData.options.optionsArray[index]"
              placeholder="请输入" :maxLength='20' :suffix="item?item.length+'/20':'0/20'" /></div>
          <div @click="deleteItem(index)" class="list_XX_R pointer"><img src="@/assets/base_img/video_delate.png"
              alt=""></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['checkData'],
  methods: {
    giveF() {
      console.log(23456789)
      this.$emit('giveF')
    },
    deleteItem(index) {
      this.checkData.options.optionsArray.splice(index, 1)
      this.giveF()
    },
    addItem() {
      this.checkData.options.optionsArray.push('请于表单工作区完善表单选项')
      this.giveF()
    }
  }
}
</script>

<style lang="less" scoped>
.login_R_Form {
  margin: 10px 24px;
  border-top: 1px solid #eff0f2;
  padding-top: 20px;
  .login_R_FormItem {
    margin-bottom: 20px;
  }
  .R_FormItem_name {
    font-weight: 500;
    font-size: 12px;
    color: #222222;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    margin-bottom: 12px;
  }
}
.choiceImg_base_Noimg {
  width: 90px;
  height: 28px;
  font-size: 12px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e6eb;
  margin-bottom: 12px;
  img {
    margin-right: 4px;
  }
}
.list_XX {
  margin-bottom: 12px;
  display: flex;
  div {
    display: flex;
    align-items: center;
  }
  .list_XX_L {
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
    text-align: right;
    font-style: normal;
    margin-right: 12px;
  }
  .list_XX_C {
    margin-right: 12px;
    flex: 1;
  }
  .list_XX_R {
    img {
      width: 16px;
    }
  }
}
.flex_must {
  font-size: 14px;
  color: #222222;
  display: flex;
  align-items: center;
  .R_FormItem_name {
    flex: 1;
  }
}
.flex_must_1 {
  flex: 1;
}
</style> 