<template>
  <div v-if="options&&options.typeof=='select'" class="video_baseFormBox">
    <div  class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-select v-model="options.value" :maxlength="options.multiple" :disabled="options.disabled"
        :clearable="options.clearable" :filterable="options.filterable" :placeholder="options.placeholder"
        :multiple="options.multiple">

        <a-select-option v-for="(item,index) in options.optionsArray" :key="'item'+index" :value="''+item">
          {{item}}
        </a-select-option>
      </a-select>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  }, 
  props: ['options', "index"],
};
</script> 
<style scoped>
.baseFormBox_data{
  width: 100%;
}
.ant-select{
  width: 100%;
}
</style>