<template>
  <div class="load">
    <div class="load_box">
      <div class="title">直播标题:</div>
      <div>{{form.title}}</div>
    </div>
    <div class="load_box">
      <div class="title">当前浏览量:</div>
      <div>{{form.view}}</div>
    </div>
    <div class="load_box">
      <div class="title">手动刷新:</div>
      <div class="">
        <a-button :disabled="disabledNum" type="primary" @click="refresh(2)">
          加2
        </a-button>
        <a-button :disabled="disabledNum" type="primary" @click="refresh(5)">
          加5
        </a-button>
        <a-button :disabled="disabledNum" type="primary" @click="refresh(10)">
          加10
        </a-button>
        <a-button :disabled="disabledNum" type="primary" @click="refresh(20)">
          加20
        </a-button>
        <a-button :disabled="disabledNum" type="primary" @click="refresh(50)">
          加50
        </a-button>
      </div>
    </div>
    <!-- <div class="load_box">
      <div class="title">自动刷新（2次/s）:</div>
      <div class="primary_box">
        <a-input v-model="targetNum" placeholder="请输入" class="primary_box_inp" addon-before="目标数量" default-value="" />
        <a-input v-model="timeNum" placeholder="请输入" class="primary_box_inp" addon-before="刷新时间" addon-after="秒"
          default-value="" />
        <a-button :disabled="disabledNum" @click="startRefresh" type="primary">
          开始
        </a-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { refreshView } from '@/api/meetApi';
export default {
  props: ['form'],
  data() {
    return {
      targetNum: '',
      timeNum: '',
      disabledNum: false
    }
  },
  methods: {
    async refresh(num) {
      console.log(this.form)
      this.disabledNum = true;
      try {
        let data = await refreshView({ live_id: this.form.id, number: num });
        this.$message.success('刷新成功');
        this.$emit('refresh', data.payload.view);
        this.disabledNum = false;
      } catch (error) {
        this.$message.error('刷新失败，请刷新网页重试！');
        this.disabledNum = false;
      }

    },
    async startRefresh() {
      this.disabledNum = true;
      if (this.targetNum < this.form.view) {
        return this.$message.error('目标数量不能小于当前浏览量')
      }
      try {
        let resNum = this.targetNum - this.form.view;
        for (let i = 0; i < resNum; i++) { // 假设循环10次
          console.log(23456)
          //   const response = await this.$axios.get(`/api/endpoint${i}`);
          // 处理每个请求的数据

          // 等待5秒后再发出下一个请求
          //   await new Promise(resolve => setTimeout(resolve, this.timeNum * 1000 / this.targetNum));
          await new Promise(resolve => setTimeout(resolve, 500));
        }
      } catch (error) {
        console.error('请求出错：', error);
      }

      this.disabledNum = false;
    }
  }
}
</script>

<style scoped>
.load {
  padding: 24px;
}
.load_box {
  display: flex;
  font-size: 14px;
  align-items: center;
  height: 50px;
  width: 100%;
}
.title {
  width: 128px;
  text-align: right;
  font-size: 14px;
  margin-right: 10px;
}
button {
  margin-right: 10px;
}
.primary_box {
  display: flex;
}
.primary_box_inp {
  width: 180px;
  margin-right: 10px;
}
</style>