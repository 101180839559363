<template>
  <div v-if="options&&options.typeof=='单选框'" class="video_baseFormBox">
    <div  class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-radio-group :disabled="options.disabled" v-model="options.value">
        <a-radio v-for="(item, index) in options.optionsArray" :key="index" :label="item" :value="item">
          {{ item }}
        </a-radio>
        <a-radio v-if="options.optionsArray && options.optionsArray.length === 0" :label="3">
          请于表单工作区完善表单选项
        </a-radio>
      </a-radio-group>
    </div>
    <slot></slot>
  </div>
</template>

<script> 
export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  methods: {
  },

  props: ['options', "index"],
};
</script> 