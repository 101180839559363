<template>
  <div class="channel">
    <div class="channel_title pb20">
      <span>启用多Tab</span>
      <span class="channel_title_at">开启后在直播详情页可显示多个tab</span>
      <a-switch :checked='tabForm.is_enabled_tab==1' @change="onChange" />
    </div>
    <div v-if="tabForm.is_enabled_tab==1" class="channel_title channel_list_box">
      <span>Tab设置</span>
      <div class="set_video pointer">
        <span @click='addItem' class="choiceImg_base_Noimg">
          <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
          添加Tab
        </span>
      </div>
    </div>

    <div v-if="tabForm.is_enabled_tab==2" class="channel_list channel_list_a">
      <div class="channel_list_item">
        <div class="list_item_Top">
          <div class="list_item_TopL">
            <span>菜单类型：</span>
            <a-select disabled default-value="1" style="flex:1">
              <a-select-option disabled value="3">
                聊天室
              </a-select-option>
              <a-select-option value="1">
                图文
              </a-select-option>
              <a-select-option value="2">
                外链
              </a-select-option>
            </a-select>
          </div>
          <div class="list_item_TopL">
            <span> 菜单名称：</span>
            <a-input disabled style="flex:1" placeholder="无" :maxLength='10' />
          </div>
        </div>

        <div class="outUrl h360">
          <Editor :form='EditorForm' @input='inputA' :key="'editorA'"></Editor>
        </div>
      </div>
    </div>

    <div v-if="tabForm.is_enabled_tab==1" class="channel_list">
      <div v-for="(item,index) in tabForm.tab_list" :key="index" class="channel_list_item">
        <img @click="delateFn(index)" class="pointer  list_item_move " width="16px"
          src="@/assets/base_img/video_delate.png" alt="">
        <img class="pointer list_item_delate " width="16px" src="@/assets/base_img/移动@2x.png" alt="">
        <div class="list_item_Top">
          <div class="list_item_TopL">
            <span>菜单类型：</span>
            <a-select v-model="item.type" default-value="1" style="flex:1">
              <a-select-option disabled value="3">
                聊天室
              </a-select-option>
              <a-select-option value="1">
                图文
              </a-select-option>
              <a-select-option value="2">
                外链
              </a-select-option>
            </a-select>
          </div>
          <div class="list_item_TopL">
            <span><span :class="{musted: item.title}" class="must">*</span> 菜单名称：</span>
            <a-input style="flex:1" v-model="item.title" placeholder="请输入" :maxLength='10'
              :suffix="item.title?item.title.length+'/10':'0/10'" />
          </div>
        </div>
        <div v-if="item.type==2" class="outUrl">
          <a-input v-model="item.url" placeholder="请以http:// 或 https:// 开头" />
        </div>
        <div v-if="item.type==1" class="outUrl h360">
          <Editor :form='{ data: { desc: item.content } }' @input='input($event,item)' :key="'editor'+index"></Editor>
        </div>
      </div>
    </div>

    <div class="send_box_video">
      <a-button @click="onSubmit">
        返回
      </a-button>
      <a-button type="primary" style="margin-left: 10px;" @click="sendForm">
        保存
      </a-button>
    </div>

  </div>
</template>

<script>
import { tabCreate, getTab } from '@/api/meetApi.js';
import setVideo from '@/views/workPlace/components/setVideo.vue'
import Editor from '@/components/Editor.vue'
export default {
  props: ['tabForm', 'videoForm', 'meetIng_id'],
  components: { setVideo, Editor },
  data() {
    return {
      EditorForm: { data: { desc: '' } },
      videoID: this.$route.query.id,
    }
  },
  created() {
    if (this.tabForm.is_enabled_tab == 2) {
      this.EditorForm.data.desc = this.videoForm.content
    }
  },
  methods: {
    async sendForm() {
      if (this.tabForm.is_enabled_tab == 2) {
        return this.$emit('createVideo')
      }
      this.tabForm.meeting_id = this.meetIng_id
      this.tabForm.live_id = this.$route.query.id
      console.log(this.tabForm)
      let data = await tabCreate(this.tabForm);
      // this.$emit('createVideo')
      this.$message.success('保存成功');
    },
    onSubmit() {
      window.history.go(-1);
    },
    delateFn(index) {
      this.tabForm.tab_list.splice(index, 1);
    },
    addItem() {
      this.tabForm.tab_list.push({
        live_id: this.$route.query.id,
        meeting_id: this.meetIng_id,
        "type": "1",
        "title": "图文",
        "url": "",
        "content": "",
        "sort": null
      })
    },
    onChange(checked) {
      if (checked) {
        this.tabForm.is_enabled_tab = 1;
      } else {
        this.tabForm.is_enabled_tab = 2;
      }
      console.log(this.tabForm.is_enabled_tab)
    },
    input(e, item) {
      item.content = e
    },
    inputA(data) {
      this.videoForm.content = data
    }
  }
}
</script>

<style lang="less" scoped>
.send_box_video {
  position: fixed;
  bottom: 10px;
  width: calc(100% - 204px);
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  left: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.channel_list_a {
  .channel_list_item {
    padding-right: 16px;
  }
}
.channel {
  margin-left: 40px;
  max-width: 720px;
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb16 {
  padding-bottom: 16px;
}
.channel_title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff0f2;
  //   padding-bottom: 24px;
  .channel_title_at {
    font-weight: 400;
    font-size: 13px;
    color: #86909d;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    flex: 1;
    margin-left: 12px;
  }
}

.channel_list_title {
  padding-bottom: 16px;
  border-bottom: 0px solid #000;
}
.channel_list_box {
  border-bottom: 0px solid #000;
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
}
.set_video {
  width: 160px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #e5e6eb;
  margin-top: 20px;
}
.choiceImg_base_Noimg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #86909d;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  img {
    margin-right: 4px;
  }
}
.channel_list_item {
  background: #f9fafc;
  border-radius: 4px;
  padding: 16px;
  position: relative;
  margin-bottom: 12px;
  margin-top: 20px;
  padding-right: 84px;
  .list_item_Top {
    display: flex;
  }
  .outUrl {
    margin-top: 16px;
  }
  .h360 {
    height: 360px;
  }
  .list_item_TopL {
    display: flex;
    flex: 1;
    align-items: center;
    margin-right: 46px;
  }
  .list_item_TopL:last-child {
    margin-right: 0;
  }
  .list_item_move {
    position: absolute;
    right: 16px;
  }
  .list_item_delate {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
  .list_item_moveA {
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .list_item_delateA {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 48px;
  }
}
.must {
  color: red;
}
.musted {
  color: green;
}
</style>