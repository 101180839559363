<template>
  <div class="login">
    <div class="login_base">
      <div class="login_L">
        <div class="login_L_title">{{formBox.form_title||'表单'}}</div>

        <draggable class="draggableBox" animation="1000" @end="endEventFN" v-model="sendList">
          <div @click="sendListFn(item,index)" v-for="(item,index) in sendList" :key="index"
            :class="{login_L_Choice: index==checkIndex}" class="login_L_item pointer">

            <component :options='item.options' :key="index" :is="item.type" :index="index"
              :class="checkIndex === index ? 'component active' : 'component'">
              <img class="solt pointer" @click="clearItem(item, index)" src="@/assets/base_img/video_delate.png" alt="">
            </component>

          </div>

        </draggable>
        <div class="login_L_send">
          <div v-if="formBox.button_image_path" :style="'background-image:url(' + formBox.button_image_path+');'"
            class="login_L_send_line">
            {{formBox.button_text||'提交'}}</div>
          <a-button v-if="!formBox.button_image_path" type="primary">
            {{formBox.button_text||'提交'}}
          </a-button>
        </div>
      </div>
      <div class="login_R">

        <div class="login_R_title pointer">
          <div @click='login_R_FN(true)' :class="{login_R_title_active: login_R_num}" class="login_R_title_T">组件类型</div>
          <div class="login_R_title_line">|</div>
          <div @click='login_R_FN(false)' :class="{login_R_title_active: !login_R_num}" class="login_R_title_T">签到设置
          </div>
        </div>

        <div v-show='login_R_num' class="zujian_box">

          <div class="login_R_btnBox">
            <a-button @click="getType(item,index)" v-for="(item,index) in btnList" :key="index"
              :type="checkType==item.name?'primary':''" :ghost='checkType==item.name'>
              {{item.label}}
            </a-button>
          </div>

          <inputFormWork @giveF='giveF' key="Inpute" :checkData='checkData' v-if='checkType=="Inpute"' />
          <textareaFormWork @giveF='giveF' key="Textarea" :checkData='checkData' v-if='checkType=="Textarea"' />
          <radioFormWork @giveF='giveF' key="Radio" :checkData='checkData' v-if='checkType=="Radio"' />
          <checkBoxFormWork @giveF='giveF' key="CheckBox" :checkData='checkData' v-if='checkType=="CheckBox"' />
          <selectFormWork @giveF='giveF' key="Selecte" :checkData='checkData' v-if='checkType=="Selecte"' />
          <timeFormWork @giveF='giveF' key="TimePicker" :checkData='checkData' v-if='checkType=="TimePicker"' />
          <cityFormWork @giveF='giveF' key="City" :checkData='checkData' v-if='checkType=="City"' />
          <articleFormWork @giveF='giveF' key="Article" :checkData='checkData' v-if='checkType=="Article"' />
          <upload_fileFormWork @giveF='giveF' key="upload_file" :checkData='checkData'
            v-if='checkType=="upload_file"' />

        </div>
        <div v-show='!login_R_num' class="zujian_box">
          <div class="zujian_box_item">
            <div class="zujian_box_itemT">表单标志<span class="zujian_box_itemT_log">（不显示）</span></div>
            <div class="zujian_box_itemD">
              <a-input v-model="formBox.form_mark" placeholder="请输入" :maxLength='100' suffix="0/100" />
            </div>
          </div>
          <div class="zujian_box_item">
            <div class="zujian_box_itemT">表单标题</div>
            <div class="zujian_box_itemD">
              <a-input v-model="formBox.form_title" placeholder="请输入" :maxLength='100' suffix="0/100" />
            </div>
          </div>
          <div class="zujian_box_item">
            <div class="zujian_box_itemT">按钮文字</div>
            <div class="zujian_box_itemD">
              <a-input v-model="formBox.button_text" placeholder="请输入" :maxLength='100' suffix="0/100" />
            </div>
          </div>
          <div class="zujian_box_item">
            <div class="zujian_box_itemT">按钮文字背景</div>
            <div class="zujian_box_itemD">
              <upload :image='formBox.button_image_path' @uploadOk='uploadButton' :showDelate='true'
                @delateDate='uploadButtonDelate' :width='"90%"' :height="'36px'"> </upload>
            </div>
          </div>
          <div class="zujian_box_item">
            <div class="zujian_box_itemT">表单背景图</div>
            <div class="zujian_box_itemD">
              <div class="zujian_box_itemImgBox">
                <upload :image='formBox.background_image_path' @uploadOk='uploadList' :showDelate='true'
                  @delateDate='uploadListDelate' :height="'138px'"> </upload>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="send_box_video">
      <a-button @click="onSubmit">
        返回
      </a-button>
      <a-button type="primary" style="margin-left: 10px;" @click="setLoginFn">
        保存
      </a-button>
    </div>
  </div>
</template>

<script>
import { geiVideoForm, createForm } from '@/api/meetApi.js';
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import upload from '@/components/upload.vue';
export default {
  props: ['meetIng_id', 'loginForm'],
  components: {
    upload, draggable
  },
  data() {
    return {
      checkIndex: 3,
      checkType: '',
      checkData: {},
      sendList: [],
      login_R_num: true,
      btnList: [
        {
          type: "inputWork",
          name: "Inpute",
          label: "单行文本",
          icon: "account-book"
        },
        {
          type: "textareaWork",
          name: "Textarea",
          label: "多行文本",
          icon: "account-book"
        },
        {
          type: "radioWork",
          name: "Radio",
          label: "单选题",
          icon: "dashboard"
        },
        {
          type: "checkBoxWork",
          name: "CheckBox",
          label: "多选题",
          icon: "dashboard"
        },
        {
          type: "selectWork",
          name: "Selecte",
          label: "下拉框",
          icon: "deployment-unit"
        },
        {
          type: "timePickerWork",
          name: "TimePicker",
          label: "日期",
          icon: "calendar"
        },
        {
          type: "cityWork",
          name: "City",
          label: "城市",
          icon: "setting"
        },
        {
          type: "articleWork",
          name: "Article",
          label: "图文",
          icon: "dashboard"
        },
        {
          type: "upload_fileWork",
          name: "upload_file",
          label: "文件上传",
          icon: "dashboard"
        },
      ],
      formBox: {
        "type": "formBox",
        "name": "formBox",
        "icon": "formBox",
        "img": "formBox",
        "meeting_id": '',
        "options": {
          "type": "formBox",
          "form_type": "2",
          "meeting_id": "",
          "data": {
            "menuArr": [],
            "pageCss": {
              'loginName': '签到',
              'loginBgc': '',
              'loginBtn': '签到',
              "page_css_margin": "0px 0px 0px 0px",
              "page_css_padding": "24px 24px 24px 24px",
              "page_css_interval": 5,
              "page_css_radius": 0,
              "page_css_bgc": "#ffffff",
              "textBgc": "rgba(255,255,255,1)",
              "myCss": ""
            }
          }
        },
      }
    }
  },
  computed: {
    // 为不修改数据格式和兼容以往数据，此处依然使用vuex中的数据。但是配置表单单独使用新组件
    ...mapGetters([
      "inputOptions",
      "selectOptions",
      'textareaOptions',
      "radioOptions",
      "sliderOptions",
      "checkBoxOptions",
      "timeOptions",
      'articleOptions',
      'upload_fileOptions',
      'cityOptions',
      "formwork",
      "switchOptions",
      "inputNumberOptions"
    ])
  },
  created() {
    const requireComponent = require.context(
      "@/views/videoSet/components/formInput",
      false,
      /\.vue$/
    );
    requireComponent.keys().forEach(fileName => {
      let config = requireComponent(fileName);
      let fimeNameChange = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
      let componentName =
        fimeNameChange.charAt(0).toLowerCase() + fimeNameChange.slice(1);
      Vue.component(componentName, config.default || config);
    });
    const requireComponent1 = require.context(
      "@/views/videoSet/components/formList",
      false,
      /\.vue$/
    );
    requireComponent1.keys().forEach(fileName => {
      let config1 = requireComponent1(fileName);
      let fimeNameChange1 = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
      let componentName1 =
        fimeNameChange1.charAt(0).toLowerCase() +
        fimeNameChange1.slice(1) +
        "Work";
      Vue.component(componentName1, config1.default || config1);
    });


    this.geiVideoForm()


  },
  methods: {
    onSubmit() { },
    endEventFN(argument) {
    },
    async geiVideoForm() {
      // let data = await geiVideoForm({ meeting_id: this.meetIng_id })
      let data = this.loginForm
      console.log(data, 123456);
      if (data && data.name) {
        this.formBox = data
        this.sendList = data.options.data.menuArr
      }
      if (this.sendList[0]) {
        this.checkIndex = 0
        this.checkType = this.sendList[0].name
        this.checkData = JSON.parse(JSON.stringify(this.sendList[0]))
      }

      console.log(this.sendList, 88888);
    },
    async setLoginFn() {
      this.sendList.forEach(element => {
        // if (element.options.value) {
        //   element.options.value = ''
        // }
      });
      console.log(this.sendList);
      this.formBox.meeting_id = this.meetIng_id
      this.formBox.options.data.menuArr = this.sendList
      let data = await createForm(this.formBox)
      this.$message.success('保存成功')
    },
    clearItem(item, index) {
      this.sendList.splice(index, 1);
      this.checkIndex = this.checkIndex - 1;
      if (this.checkIndex < 0) {
        this.type = "";
        return (this.checkIndex = 0);
      }
      this.type = this.sendList[this.checkIndex].type;
    },
    giveF() {
      this.sendList[this.checkIndex] = this.checkData
      console.log(this.sendList);
      this.$emit('loginChange', this.sendList)
      this.changeWindow()
    },
    changeWindow() {
      this.$forceUpdate()
    },
    getType(item, index) {
      this.checkIndex = this.sendList.length
      this.checkType = item.name;
      let newOption = {};
      switch (this.checkType) {
        case "Inpute":
          newOption = JSON.parse(JSON.stringify(this.inputOptions));
          break;
        case "Textarea":
          newOption = JSON.parse(JSON.stringify(this.textareaOptions));
          break;
        case "Selecte":
          newOption = JSON.parse(JSON.stringify(this.selectOptions));
          break;
        case "Slider":
          newOption = JSON.parse(JSON.stringify(this.sliderOptions));
          break;
        case "Radio":
          newOption = JSON.parse(JSON.stringify(this.radioOptions));
          break;
        case "TimePicker":
          newOption = JSON.parse(JSON.stringify(this.timeOptions));
          break;
        case "Switche":
          newOption = JSON.parse(JSON.stringify(this.switchOptions));
          break;
        case "CheckBox":
          newOption = JSON.parse(JSON.stringify(this.checkBoxOptions));
          break;
        case "Article":
          newOption = JSON.parse(JSON.stringify(this.articleOptions));
          break;
        case "City":
          newOption = JSON.parse(JSON.stringify(this.cityOptions));
          break;
        case "upload_file":
          newOption = JSON.parse(JSON.stringify(this.upload_fileOptions));
          break;
      } 
      this.$set(item, "options", JSON.parse(JSON.stringify(newOption)));
      this.sendList.push(JSON.parse(JSON.stringify(item)));
      this.checkData = JSON.parse(JSON.stringify(item));  
    },
    sendListFn(item, index) {
      this.checkIndex = index
      this.checkData = JSON.parse(JSON.stringify(item));
      this.checkType = item.name;
    },
    login_R_FN(num) {
      this.login_R_num = num;
    },
    uploadList(str) {
      if (str.status == 1) {
        this.formBox.background_image = str.payload.id
        this.formBox.background_image_path = str.payload.file_url
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    uploadListDelate() {
      this.formBox.background_image = ''
      this.formBox.background_image_path = ''
    },
    uploadButton(str) {
      if (str.status == 1) {
        this.formBox.button_image = str.payload.id
        this.formBox.button_image_path = str.payload.file_url
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    uploadButtonDelate() {
      this.formBox.button_image = ''
      this.formBox.button_image_path = ''
    },
  }
}
</script>

<style lang="less" scoped>
.zujian_box_itemT_log {
  font-weight: 400;
  font-size: 12px;
  color: #7f7f7f;
}
.send_box_video {
  position: fixed;
  bottom: 10px;
  width: calc(100% - 204px);
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  left: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solt {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
}
.zujian_box_item {
  padding: 0 24px;
  margin-bottom: 20px;
  .zujian_box_itemImgBox {
    width: 100%;
    height: 158px;
    min-height: 158px;
  }
  .zujian_box_itemT {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-bottom: 16px;
  }
}
.login {
  min-height: 100%;
  padding: 0 24px 24px 24px;
}
.login_base {
  width: 100%;
  min-height: 100%;
  display: flex;
}
.login_L,
.login_R {
  min-width: 375px;
  min-height: 664px;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebecf0;
}
.login_L {
  width: 368px;
  margin-right: 24px;
  padding-bottom: 90px;
}
.login_R {
  flex: 1;
  max-width: 396px;
  .login_R_title {
    display: flex;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #7f7f7f;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    align-items: center;
    .login_R_title_T {
      flex: 1;
      padding: 20px 0;
    }
    .login_R_title_line {
      color: #eff0f2;
    }
    .login_R_title_active {
      font-weight: 500;
      font-size: 14px;
      color: #222222;
    }
  }
  .login_R_btnBox {
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px;
    padding-right: 16px;
    button {
      padding: 0;
      width: calc(25% - 8px);
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}
.login_L_title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #222222;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  text-align: center;
  margin: 24px;
}
.login_L_item {
  min-height: 100px;
  background: #ffffff;
  //   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-left: 2px solid transparent;
  margin-bottom: 5px;
}
.login_L_Choice {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-left: 2px solid #1890ff;
}
.login_L_send_line {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  border-radius: 32px;
}
.login_L_send {
  width: 100%;
  padding: 0 30px;
  display: flex;
  position: absolute;
  height: 46px;
  bottom: 24px;
  button {
    height: 46px;
    flex: 1;
    border-radius: 23px;
  }
}
</style>
<style>
.video_baseFormBox {
  padding: 10px 30px;
  position: relative;
}
.video_baseFormBox .baseFormBox_label {
  margin-bottom: 8px;
}
</style>