<template>
  <div class="login_R_Form">
    <div class="login_R_FormItem flex_must">
      <div class="flex_must_1">文件sss上传</div>
      必填&nbsp;<a-switch size="small" @change="giveF" v-model="checkData.options.require" />
    </div>
    <div class="login_R_FormItem">
      <div class="R_FormItem_name">组件名称</div>
      <div class="R_FormItem_data">
        <a-input  @change="giveF" v-model="checkData.options.label" placeholder="请输入" :maxLength='20'
          :suffix="checkData.options.label?checkData.options.label.length+'/20':'0/20'" />
      </div>
    </div>
    <div class="login_R_FormItem">
      <p class="R_FormItem_name">文件类型</p>
      <a-radio-group  @change="giveF" v-model="checkData.options.fileType">
        <a-radio :value="'1'">
          无限制
        </a-radio>
        <a-radio :value="'2'">
          图片
        </a-radio>
        <a-radio :value="'3'">
          视频
        </a-radio>
      </a-radio-group>
    </div>

    <div class="login_R_FormItem">
      <p class="R_FormItem_name">文件大小</p>
      <a-input  @change="giveF" v-model="checkData.options.fileNumber" type='number' placeholder="请输入" suffix="m" />
    </div>

    <div class="login_R_FormItem">
      <p class="R_FormItem_name">文件数量</p>
      <a-input  @change="giveF" v-model="checkData.options.fileSize" type='number' placeholder="请输入" suffix="个" />
    </div>

  </div>
</template> 
<script>
import { mapGetters } from "vuex";
export default {
  props: ['checkData'],
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  methods: {
    giveF() {
      console.log(23456789)
      this.$emit('giveF')
    }
  }
};
</script>

<style lang="less" scoped>
.login_R_Form {
  margin: 10px 24px;
  border-top: 1px solid #eff0f2;
  padding-top: 20px;
  .login_R_FormItem {
    margin-bottom: 20px;
  }
  .R_FormItem_name {
    font-weight: 500;
    font-size: 12px;
    color: #222222;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    margin-bottom: 12px;
  }
}
.flex_must {
  font-size: 14px;
  color: #222222;
  display: flex;
  align-items: center;
  .R_FormItem_name {
    flex: 1;
  }
}
.flex_must_1 {
  flex: 1;
}
.ant-radio-wrapper {
  margin-right: 0;
}
</style> 