<template>
  <div v-if="options&&options.typeof=='slider'" class="video_baseFormBox">
    <div  class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-slider v-model="options.value" :vertical="options.vertical" :height="options.height + 'px'"
        :min="options.min" :max="options.max" :show-tooltip="options.showTooltip" :disabed="options.disabed"
        :step="options.step" :show-stops="options.showStops" :show-input="options.showInput"
        :range="options.range"></a-slider>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {}
    };
  },

  props: ['options', "index"],
};
</script> 