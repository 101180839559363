<template>
  <div v-if="options&&options.typeof=='多选框'" class="video_baseFormBox">
    <div  class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-checkbox-group v-model="options.value">
        <a-checkbox v-for="(item,index) in options.optionsArray" :key="'item'+index" :value="''+item">{{
            item
          }}</a-checkbox>
      </a-checkbox-group>
    </div>
    <slot></slot>
  </div>
</template>

<script> 
export default {
  data() {
    return {
      form: {},
      keyValue: 0,
      checkedCities: []
    };
  },
  props: ['options', "index"],
  creasted(){
    console.log(this.options);
  },
  methods: {
  },
};
</script> 
<style>
.ant-checkbox-wrapper{
  margin-left: 0 !important;
  margin-right: 8px;
}
</style>