<template>
  <div class="video_set">

    <div v-if="form.id" class="dataMsg">
      <div class="video_set_title">直播配置</div>
      <dataBox @changeForm='changeForm' :dataMsg='form' :meeting_data="meeting_data"></dataBox>
    </div>

  </div>
</template>

<script>
import { geiVideodetail, createVideoList } from '@/api/meetApi.js';
import dataBox from './components/data.vue';

import moment from "moment";
export default {
  components: {
    dataBox
  },
  data() {
    return {
      dataMsg: 1234,
      form: {},
      meeting_data: {}
    }
  },
  mounted() {

  },
  methods: {
    moment,
    changeForm(data) {
      this.form = data
      this.stop()
    },
    confirm() {
      this.$message.success('删除成功!');
    },
    cancel() { },
    async save() {

      let data = await createVideoList(this.form)
      this.$message.success('保存成功！')
      this.stop()
    },
    stop() {
      // window.isCloseHint = true;
      //初始化关闭
      window.addEventListener("beforeunload", function (e) {
        if (window.isCloseHint) {
          var confirmationMessage = "要记得保存！你确定要离开我吗？";
          (e || window.event).returnValue = confirmationMessage; // 兼容 Gecko + IE
          return confirmationMessage; // 兼容 Gecko + Webkit, Safari, Chrome
        }
      });
    },
    noStop() {
      window.isCloseHint = false;
    },

    async getData() {
      let data = await geiVideodetail({ id: this.$route.query.id })
      console.log(data, 2345)
      this.form = data.payload.data
      this.meeting_data = data.payload.meeting_data

      this.SET_WEB_ROUTER(['直播', this.form.title])

      console.log(this.moment(this.form.end_time).format('X'))
    }
  },
  async created() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.video_set_title {
  padding: 12px 24px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 18px;
  color: #222222;
  line-height: 25px;
  text-align: left;
  font-style: normal;
}
.video_set {
  // max-width: 1400px;
  margin: auto;
  padding: 0 12px;
  margin-bottom: 12px;
  // margin: 0 20px 20px 20px;
}
.titleBox {
  height: 60px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  padding: 0 18px;
  div {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
  }
}
.status {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 4px;
  margin-left: 5px;
}
.status_begin {
  background: rgb(232, 234, 237);
  color: rgb(153, 153, 153);
}
.status_doing {
  background: rgba(62, 247, 71, 0.5);
  color: rgb(249, 250, 251);
}
.status_end {
  background: rgba(251, 2, 2, 0.5);
  color: rgb(251, 252, 253);
}

.dataMsg {
  margin-top: 12px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.fl1 {
  flex: 1;
}
.mr20 {
  margin-right: 20px;
}
</style> 
<style>
.baseFormBox_data .ant-cascader-picker,
.video_baseFormBox .ant-calendar-picker {
  width: 100%;
}
</style>