<template>
  <div class="login_R_Form">
    <Editor @input='input' :form="form" />
  </div>
</template>

<script>
import Editor from '@/components/Editor.vue';
export default {
  components: { Editor },
  props: ['checkData'],
  data() {
    return {
      form: {
        data: {
          desc: ''
        }
      }
    }
  },
  created() {
    this.form.data.desc = this.checkData.options.value
  },
  methods: {
    async input(val) {
      this.checkData.options.value = await JSON.parse(JSON.stringify(val))
      setTimeout(() => {
        this.giveF()
      },100)
    },
    giveF() {
      this.$emit('giveF')
    }
  }
}
</script>

<style lang="less" scoped>
.login_R_Form {
  margin: 10px 24px;
  border-top: 1px solid #eff0f2;
  padding-top: 20px;
  height: 600px;
  min-height: 600px;
  .editor {
    height: 100%;
  }
  .login_R_FormItem {
    margin-bottom: 20px;
  }
  .R_FormItem_name {
    font-weight: 500;
    font-size: 12px;
    color: #222222;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    margin-bottom: 12px;
  }
}
</style> 