<template>
  <div v-if="options&&options.typeof=='input'" class="video_baseFormBox">
    <div  class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-input :type="options.type" v-model="options.value" :disabled="options.disabled"
        :prefixIcon="options.prefixIcon" :suffixIcon="options.suffixIcon" :placeholder="options.placeholder">
        <template v-if="options.slot !== ''" slot="append">
          {{ options.slot }}
        </template>
      </a-input>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  props: ['options', "index"],
};
</script> 