<template>
  <div class="video_baseFormBox">
    <div v-if="options&&options.typeof=='article'" v-html="options.value" class="article_html">

    </div>
    <slot></slot>
  </div>

</template>

<script> 
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  methods: {
  },
  props: ['options', "index"],
};
</script> 
<style scoped>
.article_html {
  /* 强制换行 */
  word-break: break-all;
}
</style>