<template>
  <div class="dataBox">
    <div class="dataBox_title">
      <a-menu v-model="current" mode="horizontal">
        <a-menu-item key="mail">基本</a-menu-item>
        <a-menu-item v-if="meeting_id&&form.type!=3&&form.type!=4" key="channel">多频道</a-menu-item>
        <a-menu-item v-if="meeting_id&&form.type!=3&&form.type!=4" key="tabList">Tab</a-menu-item>
        <a-menu-item v-if="meeting_id&&form.type!=3&&form.type!=4" key="login">签到</a-menu-item>
        <!-- <a-menu-item v-if="meeting_id&&form.type!=3&&form.type!=4" key="loaded">刷新</a-menu-item> -->
      </a-menu>
    </div>

    <div class="page">

      <!-- 左侧页面效果展示 -->
      <div v-show="!(current[0]=='join')" class="page_l">
        <div v-if="form.type!=2" class="model lw_video_bg">
          <div class="phone_title_home">
            <img width="100%" src="@/assets/phone_title.png" alt="">
            <div class="phone_title_txt text">{{form.title}}</div>
          </div>
          <div class="video_poster">
            <div v-if="!form.cover_path" class="no_imgPoster">
              <img width="32px" src="@/assets/play@2x.png" alt="">
            </div>
            <div v-if="form.cover_path" class="imgPoster">
              <img width="100%" height="100%" :src="form.cover_path" alt="">
            </div>
          </div>
          <!-- 多频道列表 -->
          <div v-if="channelData.channel_status==1" class="channleList">

            <div v-for="(item,index) in channelData.channel_list" :key="index" class="channleListItem">
              <div class="channleListItem_cover channleListItem_coverMY">
                <div v-if="item.status!=1">
                  <img v-if="item.status==2" width="10px" src="@/assets/base_img/icon_again@2x.png" alt="">
                  <img v-if="item.status==0" width="10px" src="@/assets/base_img/icon_not@2x.png" alt="">
                  <span v-if="item.status==2&&form.is_multilingual==2">已结束</span>
                  <span v-if="item.status==2&&form.is_multilingual==1">Ended</span>
                  <span v-if="item.status==0&&form.is_multilingual==2">未开始</span>
                  <span v-if="item.status==0&&form.is_multilingual==1">Not started</span>
                </div>
                <div class="channleListItem_coverMY_icon"><img src="@/assets/base_img/矩形channl.png" alt=""></div>
                <div style="flex:1"></div>
                <div class="text"><img width="10px" v-if="item.live_id==$route.query.id"
                    src="@/assets/base_img/icon_live@2x.png" alt="">{{item.channel_title||item.title}}
                </div>
              </div>
              <img class="channleListItem_cover_img" :src="item.cover_path" alt="">
            </div>

          </div>
          <!-- tab按钮 -->
          <div v-show='tabForm.is_enabled_tab==1' class="video_title">
            <div @click="changeListNum(index,item)" v-for="(item,index) in tabForm.tab_list" :key="index">
              {{item.title}}
              <span v-show="index==listNum" class="line"></span>
            </div>
          </div>
          <!-- tab详情，当配置图文tab时，第一个图文显示直播信息，其他隐藏 -->
          <div v-show='tabForm.is_enabled_tab==1&&listNum==index' v-for="(item,index) in  tabForm.tab_list" :key="index"
            class="showBox">
            <div v-show='item.type=="1"' class="p12 synopsis video_message">
              <div v-show="index==0" class="video_name">
                {{form.title}}
              </div>

              <div v-show="index==0" class="textBox">
                <div v-if="form.begin_time" class="message">
                  <img width="12px" src="@/assets/auto_img/时间@2x.png" alt="">
                  <span>{{form.is_multilingual==1?'Time：':'时间：'}}</span><span>{{form.begin_time | time('YYYY.MM.DD')}}
                    {{form.begin_time | time('HH:mm')}}-{{form.end_time | time('HH:mm')}}</span>
                </div>
                <div v-if="form.speaker" class="message">
                  <img width="12px" src="@/assets/术者@2x.png" alt="">
                  <span>{{form.is_multilingual==1?'Speaker：':'术者：'}}</span><span>{{form.speaker}}</span>
                </div>
                <div v-if="form.address" class="message">
                  <img width="12px" src="@/assets/auto_img/地点@2x.png" alt="">
                  <span>{{form.is_multilingual==1?'Location：':'地点：'}}</span><span>{{form.address}}</span>
                </div>
                <div v-if="form.view" class="message">
                  <img width="12px" src="@/assets/auto_img/观看@2x.png" alt="">
                  <span>{{form.is_multilingual==1?'Views：':'观看：'}}</span><span>{{form.view}}</span>
                </div>
                <div v-if='form.is_sign_in==1' class="is_sign_in">
                  <img v-if="!form.sign_in_pic_path" width="100%" src="@/assets/签到@2x.png" alt="">
                  <img v-if="form.sign_in_pic_path" width="100%" :src="form.sign_in_pic_path" alt="">
                </div>
                <div v-if='form.type==2&&form.tag_log_path' class="is_sign_in">
                  <img v-if="form.tag_log_path" width="100%" :src="form.tag_log_path" alt="">
                </div>
              </div>
              <div v-html="item.content" class="haibao"></div>
            </div>
            <div v-show='item.type==2' @click='goOutUrl(item)' class="p12 richText"></div>
            <!-- 聊天框，聊天功能暂时未开发 -->
            <div v-show='item.type==3' class="p12 out_url">聊天框</div>
          </div>
          <!-- 没有tab时直接显示直播信息 -->
          <div v-show='tabForm.is_enabled_tab==2' class="showBox p12 synopsis video_message">
            <div class="video_name">
              {{form.title}}
            </div>
            <div class="textBox">
              <div v-if="form.begin_time" class="message">
                <img width="12px" src="@/assets/auto_img/时间@2x.png" alt="">
                <span>{{form.is_multilingual==1?'Time：':'时间：'}}</span><span>{{form.begin_time | time('YYYY.MM.DD')}}
                  {{form.begin_time | time('HH:mm')}}-{{form.end_time | time('HH:mm')}}</span>
              </div>
              <div v-if="form.speaker" class="message">
                <img width="12px" src="@/assets/术者@2x.png" alt="">
                <span>{{form.is_multilingual==1?'Speaker：':'术者：'}}</span><span>{{form.speaker}}</span>
              </div>
              <div v-if="form.address" class="message">
                <img width="12px" src="@/assets/auto_img/地点@2x.png" alt="">
                <span>{{form.is_multilingual==1?'Location：':'地点：'}}</span><span>{{form.address}}</span>
              </div>
              <div v-if="form.view" class="message">
                <img width="12px" src="@/assets/auto_img/观看@2x.png" alt="">
                <span>{{form.is_multilingual==1?'Views：':'观看：'}}</span><span>{{form.view}}</span>
              </div>
              <div v-if='form.is_sign_in==1' class="is_sign_in">
                <img v-if="!form.sign_in_pic_path" width="100%" src="@/assets/签到@2x.png" alt="">
                <img v-if="form.sign_in_pic_path" width="100%" :src="form.sign_in_pic_path" alt="">
              </div>
              <div v-if='form.type==2&&form.tag_log_path' class="is_sign_in">
                <img v-if="form.tag_log_path" width="100%" :src="form.tag_log_path" alt="">
              </div>
            </div>
            <div v-html="form.content" class="haibao"></div>
          </div>
          <div v-show="current[0]=='login'" class="loginForm">
            <div
              :style="loginForm.options.data.pageCss.loginBgc?'background: url('+loginForm.options.data.pageCss.loginBgc+')':''"
              class="loginFormBase">
              <div class="login_L_title">{{loginForm.form_title||'表单'}}</div>

              <div v-for="(item,index) in loginForm.options.data.menuArr" :key="index" class="login_L_item pointer">

                <component :options='item.options' :key="index" :is="item.type" :index="index">
                </component>

              </div>
              <div style="flex:1"></div>

              <div class="login_L_send">
                <div v-if="loginForm.button_image_path"
                  :style="'background-image:url(' + loginForm.button_image_path+');'" class="login_L_send_line">
                  {{loginForm.button_text||'提交'}}</div>
                <a-button v-if="!loginForm.button_image_path" type="primary">
                  {{loginForm.button_text||'提交'}}
                </a-button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="form.type==2" class="model lw_video_bg">
          <div class="phone_title_home">
            <img width="100%" src="@/assets/phone_title.png" alt="">
            <div class="phone_title_txt text">{{form.title}}</div>
          </div>

          <div v-if="meeting_data&&meeting_data.video_img_path" class="header">
            <img width="100%" :src="meeting_data.video_img_path" alt="">
          </div>
          <div class="video_mp4">
            <div class="video_poster">
              <div class="no_imgPoster">
                <img width="32px" src="@/assets/play@2x.png" alt="">
              </div>
              <div v-if="form.cover_path" class="imgPoster">
                <img width="100%" height="100%" :src="form.cover_path" alt="">
              </div>
            </div>
            <!-- tab按钮 -->
            <div v-show='tabForm.is_enabled_tab==1' class="video_title">
              <div @click="changeListNum(index,item)" v-for="(item,index) in tabForm.tab_list" :key="index">
                {{item.title}}
                <span v-show="index==listNum" class="line"></span>
              </div>
            </div>
            <!-- tab详情，当配置图文tab时，第一个图文显示直播信息，其他隐藏 -->
            <div v-show='tabForm.is_enabled_tab==1&&listNum==index' v-for="(item,index) in  tabForm.tab_list"
              :key="index" class="showBox">
              <div v-show='item.type=="1"' class="p12 synopsis video_message">
                <div v-html="item.content" class="haibao"></div>
              </div>
              <div v-show='item.type==2' @click='goOutUrl(item)' class="p12 richText"></div>
            </div>
            <!-- 没有tab时直接显示直播信息 -->
            <div v-show='tabForm.is_enabled_tab==2' class="showBox p12 synopsis video_message no_tab">
              <div v-html="form.content" class="haibao"></div>
            </div>
          </div>
          <!-- 签到 -->
          <div v-show="current[0]=='login'" class="loginForm">
            <div
              :style="loginForm.options.data.pageCss.loginBgc?'background: url('+loginForm.options.data.pageCss.loginBgc+')':''"
              class="loginFormBase">
              <div class="login_L_title">{{loginForm.form_title||'表单'}}</div>

              <div v-for="(item,index) in loginForm.options.data.menuArr" :key="index" class="login_L_item pointer">

                <component :options='item.options' :key="index" :is="item.type" :index="index">
                </component>

              </div>
              <div style="flex:1"></div>

              <div class="login_L_send">
                <div v-if="loginForm.button_image_path"
                  :style="'background-image:url(' + loginForm.button_image_path+');'" class="login_L_send_line">
                  {{loginForm.button_text||'提交'}}</div>
                <a-button v-if="!loginForm.button_image_path" type="primary">
                  {{loginForm.button_text||'提交'}}
                </a-button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- 基本信息配置 -->
      <div v-show="current[0]=='mail' && form.id" class="page_c">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item ref="title" label="直播名称" prop="title">
            <a-input size="large" v-model="form.title" @blur=" () => { $refs.title.onFieldBlur(); } " />
          </a-form-model-item>
          <a-form-model-item ref="meeting_name" label="所属门户" prop="meeting_name">
            <a-input size="large" disabled v-model="meeting_data.meeting_name"
              @blur=" () => { $refs.meeting_name.onFieldBlur(); } " />
          </a-form-model-item>
          <a-form-model-item v-if="form.type==1||form.type==3" class="no_label_icon" prop="begin_time">
            <template #label>
              <span class="label-with-image">
                直播时间:
              </span>
              <tooltip :txt='"直播开始时间和直播结束时间"'></tooltip>
            </template>
            <div class="video_time">
              <a-date-picker size="large" format="YYYY-MM-DD HH:mm:ss"
                :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="开始时间" v-model="begin_time"
                style="width: 100%;" />
              <span>&nbsp;&nbsp;&nbsp;至&nbsp;&nbsp;&nbsp; </span>
              <a-date-picker size="large" format="YYYY-MM-DD HH:mm:ss"
                :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="结束时间" v-model="end_time"
                style="width: 100%;" />
            </div>
          </a-form-model-item>
          <a-form-model-item v-if="form.type==2||form.type==4" class="no_label_icon" prop="begin_time">
            <template #label>
              <span class="label-with-image">
                开始时间:
              </span>
              <tooltip :txt='"视频的开始时间"'></tooltip>
            </template>
            <a-date-picker size="large" format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="开始时间" v-model="begin_time"
              style="width: 100%;" />
          </a-form-model-item>
          <a-form-model-item v-if="form.type==2||form.type==4" class="no_label_icon" prop="begin_time">
            <template #label>
              <span class="label-with-image">
                开始时间:
              </span>
              <tooltip :txt='"视频的结束时间"'></tooltip>
            </template>
            <a-date-picker size="large" format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="开始时间" v-model="end_time"
              style="width: 100%;" />
          </a-form-model-item>
          <a-form-model-item class="no_label_icon" ref="speaker" prop="speaker">
            <template #label>
              <span class="label-with-image">
                术者:
              </span>
            </template>
            <a-input size="large" placeholder="请输入术者" v-model="form.speaker"
              @blur=" () => { $refs.speaker.onFieldBlur(); } " />
          </a-form-model-item>
          <a-form-model-item class="no_label_icon" ref="address" prop="address">
            <template #label>
              <span class="label-with-image">
                活动地点:
              </span>
              <tooltip :txt='"举办活动的地点"'></tooltip>
            </template>
            <a-input size="large" placeholder="请输入活动地点" v-model="form.address"
              @blur=" () => { $refs.address.onFieldBlur(); } " />
          </a-form-model-item>
          <a-form-model-item ref="listCover" label="列表封面" prop="listCover">
            <div class="video_list_img">
              <div class="video_list_imgUp">
                <upload :image='form.list_cover_path' @uploadOk='uploadList' :showDelate='true'
                  @delateDate='uploadListDelate' :height="'70px'"> </upload>
              </div>
              <div class="video_list_imgP">
                <p>【缩略图列表】比例为4:3 <tooltip :txt='"用于略缩图列表显示，建议图片比例为4:3，否则将会被拉伸。"'></tooltip>
                </p>
                <p>【大图列表】比例为16:9 <tooltip :txt='"用于大图列表显示，建议图片比例为16:9，否则将会被拉伸。"'></tooltip>
                </p>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item ref="view" class="no_label_icon" prop="view">
            <template #label>
              <span class="label-with-image">
                浏览量:
              </span>
              <tooltip :txt='"当前浏览量，单次点击一次加10"'></tooltip>
            </template>
            <div class="number_btn_home">
              <div @click="number_inp(-10)" class="number_btn pointer">-</div>
              <a-input class="number_inp" v-model="form.view" size="large"
                @blur=" () => { $refs.view.onFieldBlur(); } " />
              <div @click="number_inp(10)" class="number_btn pointer">+</div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="类型" prop="status">
            <a-button-group class="lineBtn">
              <a-button :class="{check2: form.type==1}" @click="changeBtn(1)" block>直播</a-button>
              <a-button :class="{check2: form.type==3}" @click="changeBtn(3)" block>议程</a-button>
              <a-button :class="{check2: form.type==4}" @click="changeBtn(4)" block>外链</a-button>
              <a-button :class="{check2: form.type==2}" @click="changeBtn(2)" block>视频</a-button>
            </a-button-group>
          </a-form-model-item>
          <a-form-model-item v-if="form.type==1||form.type==4" label="直播状态" prop="status">
            <a-button-group class="lineBtn">
              <a-button :class="{check2: form.status==0}" @click="changeBtnStatus(0)" block>未开始</a-button>
              <a-button :class="{check2: form.status==1}" @click="changeBtnStatus(1)" block>直播中</a-button>
              <a-button :class="{check2: form.status==2}" @click="changeBtnStatus(2)" block>已结束</a-button>
            </a-button-group>
          </a-form-model-item>
          <a-form-model-item v-if="form.type==1&&meeting_id" label="是否签到" prop="is_sign_in">
            <a-switch :default-checked='form.is_sign_in==1' @change="is_sign_in_onChange" />
          </a-form-model-item>
          <a-form-model-item v-if="form.is_sign_in==1" label="强制签到" prop="is_must_sign_in">
            <a-switch :default-checked='form.is_must_sign_in==1' @change="is_must_sign_in_onChange" />
          </a-form-model-item>
          <a-form-model-item v-if="form.is_sign_in==1" label="签到自动弹出" prop="is_first_display_sign_in">
            <a-switch :default-checked='form.is_first_display_sign_in==1' @change="is_first_display_sign_in_onChange" />
          </a-form-model-item>
          <a-form-model-item v-show="form.is_sign_in==1" has-feedback label="签到按钮图片">
            <div class="img_login">
              <upload :image='form.sign_in_pic_path' @uploadOk='uploadOkLogin' :showDelate='true'
                @delateDate='delateDateLogin' :width="'80px'">
              </upload>
            </div>
          </a-form-model-item>
          <a-form-model-item v-if="form.type==1||form.type==2" label="是否英文" prop="is_sign_in">
            <a-switch :default-checked='form.is_multilingual==1' @change="is_multilingual_onChange" />
          </a-form-model-item>
          <a-form-model-item v-if="form.type==1||form.type==2" ref="listCover" label="视频封面" prop="listCover">
            <div class="listCover_img">
              <upload :image='form.cover_path' @uploadOk='uploadCover_path' :showDelate='true'
                @delateDate='cover_pathDelate' :action='"建议长宽比为16:9"' :width="'auto'" :height="'138px'"> </upload>
            </div>
          </a-form-model-item>
          <a-form-model-item ref="listCover" label="背景" prop="listCover">
            <div class="listCover_img">
              <upload :image='form.video_background_path' @uploadOk='video_background_path' :showDelate='true'
                @delateDate='video_backgroundDelate' :action='"建议长宽比为16:9"' :width="'auto'" :height="'138px'"> </upload>
            </div>
          </a-form-model-item>
          <a-form-model-item v-if="form.type==3" ref="listCover" label="议程海报" prop="listCover">
            <div class="listCover_img">
              <upload :image='form.poster_path' @uploadOk='uploadPoster_path' :showDelate='true'
                @delateDate='poster_pathDelate' :action='"建议长宽比为16:9"' :width="'auto'" :height="'138px'"> </upload>
            </div>
          </a-form-model-item>
          <a-form-model-item v-if="form.type==2" ref="listCover" label="厂商logo" prop="listCover">
            <div style="justify-content: unset;" class="video_list_img">
              <div class="video_list_imgUp">
                <upload :image='form.tag_log_path' @uploadOk='uploadCs_logo' :showDelate='true'
                  @delateDate='cs_logoDelate' :action='""' :width="'auto'" :height="'70px'"> </upload>
              </div>
              <!-- <div class="video_list_imgP">
                <p>【缩略图列表】比例为4:3 <tooltip :txt='"用于略缩图列表显示，建议图片比例为4:3，否则将会被拉伸。"'></tooltip>
                </p>
                <p>【大图列表】比例为16:9 <tooltip :txt='"用于大图列表显示，建议图片比例为16:9，否则将会被拉伸。"'></tooltip>
                </p>
              </div> -->
            </div>
          </a-form-model-item>
          <a-form-model-item v-if="form.type==1" ref="live_link" label="直播链接" prop="">
            <a-input size="large" v-model="form.live_link" @blur=" () => { $refs.live_link.onFieldBlur(); } " />
          </a-form-model-item>
          <a-form-model-item v-if="form.type==2" ref="live_link" label="视频链接" prop="">
            <a-input size="large" v-model="form.live_link" @blur=" () => { $refs.live_link.onFieldBlur(); } " />
          </a-form-model-item>
          <a-form-model-item v-if="form.type==1" ref="back_link" label="回放链接" prop="back_link">
            <a-input size="large" v-model="form.back_link" @blur=" () => { $refs.back_link.onFieldBlur(); } " />
          </a-form-model-item>
          <a-form-model-item v-if="form.type==1&&!form.back_link" ref="back_link" label="结束语" prop="peroration">
            <a-input size="large" v-model="form.peroration" @blur=" () => { $refs.peroration.onFieldBlur(); } " />
          </a-form-model-item>
          <a-form-model-item v-if="form.type==4" ref="external_url" label="外链地址" prop="external_url">
            <a-input size="large" v-model="form.external_url" @blur=" () => { $refs.external_url.onFieldBlur(); } " />
          </a-form-model-item>
          <div class="send_box_video">
            <a-button type="primary" @click="onSubmit">
              保存
            </a-button>
            <a-button style="margin-left: 10px;" @click="resetForm">
              重置
            </a-button>
          </div>
        </a-form-model>
      </div>
      <!-- 多频道配置 -->
      <div v-show="current[0]=='channel' && form.id" class="page_c">
        <channelList @changeChannel='changeChannel' :channelData='channelData' :videoForm='form'
          :meetIng_id='meeting_id' />
      </div>
      <!-- Tab配置 -->
      <div v-show="current[0]=='tabList' && form.id" class="page_c">
        <tabList @createVideo='onSubmit' :tabForm='tabForm' :videoForm='form' :meetIng_id='meeting_id' />
      </div>
      <!-- 签到配置 -->
      <div v-if="meeting_id" v-show="current[0]=='login' && form.id" class="page_c">
        <login @loginChange="loginChange" v-if="openLoginForm" :loginForm='loginForm' :meetIng_id='meeting_id' />
      </div>
      <!-- 刷新 -->
      <div v-if="meeting_id" v-show="current[0]=='loaded' && form.id" class="page_c">
        <loaded @refresh='refresh' :form='form' :meetIng_id='meeting_id' />
      </div>
    </div>
  </div>
</template>

<script>
import { getTab, channelGet, geiVideoForm, createVideoList } from '@/api/meetApi.js';
import upload from '@/components/upload.vue';
import loaded from './loaded.vue';
import channelList from './channelList.vue';
import tabList from './tabList.vue';
import Vue from "vue";
import login from './login.vue';
import moment from "moment";
import tooltip from "@/components/tooltip.vue";
export default {
  props: ['dataMsg', 'meeting_data'],
  components: { tooltip, loaded, channelList, tabList, login, upload, },
  data() {
    return {
      meeting_id: this.$route.query.meeting_id,
      openLoginForm: false,
      channelData: {
        channel_list: [],
        channel_status: 2,
        sort_type: 1
      },
      loginForm: {
        "type": "formBox",
        "name": "formBox",
        "icon": "formBox",
        "img": "formBox",
        "meeting_id": '',
        "options": {
          "type": "formBox",
          "form_type": "2",
          "meeting_id": "",
          "data": {
            "menuArr": [],
            "pageCss": {
              'loginName': '签到',
              'loginBgc': '',
              'loginBtn': '签到',
              "page_css_margin": "0px 0px 0px 0px",
              "page_css_padding": "24px 24px 24px 24px",
              "page_css_interval": 5,
              "page_css_radius": 0,
              "page_css_bgc": "#ffffff",
              "textBgc": "rgba(255,255,255,1)",
              "myCss": ""
            }
          }
        },
      },
      tabForm: {
        "is_enabled_tab": 2,
        "meeting_id": "",
        "live_id": "",
        "tab_list": []
      },
      begin_time: null,
      end_time: null,
      listNum: 0,
      Editor: { data: { richText: '' } },
      picture: {},
      current: ['mail'],
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      form: {},
      rules: {
        title: [{ required: true, message: '请输入直播标题' }],
        begin_time: [{ required: true, message: '请选择开始时间', }],
        end_time: [{ required: true, message: '请选择结束时间', }],
        status: [{ required: true, message: '请选择', }],
        meeting_name: [{ required: true, message: '请输入会议名称', }],
      },
    };
  },
  async created() {
    const requireComponent = require.context(
      "@/views/videoSet/components/formInput",
      false,
      /\.vue$/
    );
    requireComponent.keys().forEach(fileName => {
      let config = requireComponent(fileName);
      let fimeNameChange = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
      let componentName =
        fimeNameChange.charAt(0).toLowerCase() + fimeNameChange.slice(1);
      Vue.component(componentName, config.default || config);
    });

    this.form = this.dataMsg
    if (!this.meeting_id && this.form.meeting_id) {
      this.meeting_id = this.form.meeting_id
    }
    this.form.meeting_name = this.meeting_data.meeting_name || ''
    console.log(this.form)
    this.begin_time = this.moment(this.form.begin_time * 1000)
    this.end_time = this.moment(this.form.end_time * 1000)
    if (!this.form.menuList[0]) {
      this.form.menuList.push({
        title: '会议议程',
        type: 'synopsis',
        richText: ''
      })
    }
    console.log(this.form)
    await this.getVideoForm()
    await this.getChannelGet()
    await this.getTabFn()
  },
  watch: {
    // 监听form变化将每一个改动提交到父亲
    form: {
      handler(item1, item2) {
        this.$emit('changeForm', this.form)
        console.log(this.form)
      },
      deep: true
    }
  },
  methods: {
    moment,
    refresh(e) {
      this.form.view = e
    },
    loginChange(data) {
      this.loginForm.options.data.menuArr = data
      this.$forceUpdate()
    },
    async getChannelGet() {
      if (!this.meeting_id) {
        return
      }
      let data = await channelGet({ meeting_id: this.meeting_id, live_id: this.$route.query.id });
      this.channelData = data.payload;
      console.log(this.channelData)
    },
    changeBtn(e) {
      this.form.type = e
    },
    changeBtnStatus(e) {
      this.form.status = e
    },
    is_sign_in_onChange(checked, event) {
      if (checked) {
        return this.form.is_sign_in = 1
      }
      this.form.is_sign_in = 0
    },
    is_must_sign_in_onChange(checked, event) {
      if (checked) {
        return this.form.is_must_sign_in = 1
      }
      this.form.is_must_sign_in = 0
    },
    is_first_display_sign_in_onChange(checked, event) {
      if (checked) {
        return this.form.is_first_display_sign_in = 1
      }
      this.form.is_first_display_sign_in = 0
    },
    is_multilingual_onChange(checked, event) {
      if (checked) {
        return this.form.is_multilingual = 1
      }
      this.form.is_multilingual = 2
    },
    uploadList(str) {
      if (str.status == 1) {
        this.form.list_cover_path = str.payload.path
        this.form.list_cover = str.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    uploadListDelate() {
      this.form.list_cover_path = ''
      this.form.list_cover = ''
    },
    cover_pathDelate() {
      this.form.cover_path = ''
      this.form.cover = ''
    },
    video_backgroundDelate() {
      this.form.video_background = ''
      this.form.video_background_path = ''
      this.changeVideoBgc()
    },
    poster_pathDelate() {
      this.form.poster_path = ''
      this.form.poster = ''
    },
    uploadPoster_path(str) {
      if (str.status == 1) {
        this.form.poster_path = str.payload.path
        this.form.poster = str.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    uploadCover_path(str) {
      if (str.status == 1) {
        this.form.cover_path = str.payload.path
        this.form.cover = str.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    video_background_path(str) {
      if (str.status == 1) {
        this.form.video_background_path = str.payload.path
        this.form.video_background = str.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
      this.changeVideoBgc()
    },
    uploadCs_logo(str) {
      if (str.status == 1) {
        this.form.tag_log_path = str.payload.path
        this.form.tag_log = str.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    cs_logoDelate() {
      this.form.tag_log_path = ''
      this.form.tag_log = ''
    },
    number_inp(num) {
      if (num < 0 && this.form.view < 10) {
        return this.form.view = 0
      }
      this.form.view = this.form.view + num
    },
    // 获取表单数据，取出id暂存。将素组赋值给vuex用于表单配置
    // async getVideoForm() {
    //   if (!this.meeting_id) {
    //     return
    //   }
    //   let data = await geiVideoForm({ meeting_id: this.meeting_id })
    //   if (data.payload) {
    //     this.loginForm = data.payload
    //     this.openLoginForm = true
    //   }
    // },
    async getVideoForm() {
      if (!this.meeting_id) {
        return
      }
      let data = await geiVideoForm({ meeting_id: this.meeting_id })
      if (data.payload && data.payload.id) {
        this.loginForm = data.payload
      } else {
        this.loginForm = {

          "type": "formBox",
          "name": "表单",
          "icon": "icon-m-gundongwenzi",
          "form_mark": "签到",
          "form_title": "签到",
          "button_text": "签到",
          "background_image": 1,
          "background_image_path": "http://192.168.3.56:9520/storage/file/2024-08/cssMtfjzQwbDZvCTjfHGhjtBE5NOa6rYwBKk7CCo.pdf",
          "options": {
            "type": "formBox",
            "form_type": 2,
            "data": {
              "menuArr": [
                {
                  "name": "Inpute",
                  "type": "inputWork",
                  "label": "单行文本",
                  "icon": "account-book",
                  "sort": 1,
                  "options": {
                    "typeof": "input",
                    "col": 24,
                    "label": "姓名",
                    "labelWidth": 200,
                    "value": null,
                    "type": "text",
                    "clearable": false,
                    "disabled": false,
                    "prefixIcon": null,
                    "suffixIcon": null,
                    "placeholder": "请填写真实姓名",
                    "requireMsg": null,
                    "require": false,
                    "reg": null,
                    "regMsg": null
                  }
                },
                {
                  "name": "Selecte",
                  "type": "selectWork",
                  "label": "下拉框",
                  "icon": "deployment-unit",
                  "sort": 2,
                  "options": {
                    "typeof": "select",
                    "col": 24,
                    "label": "性别",
                    "labelWidth": 200,
                    "multiple": false,
                    "clearable": false,
                    "filterable": false,
                    "disabled": false,
                    "placeholder": "请选择",
                    "optionsType": true,
                    "optionsArray": [
                      "男",
                      "女",
                    ],
                    "optionsZd": null,
                    "requireMsg": null,
                    "require": false
                  }
                },
                {
                  "name": "Inpute",
                  "type": "inputWork",
                  "label": "单行文本",
                  "icon": "account-book",
                  "sort": 3,
                  "options": {
                    "typeof": "input",
                    "col": 24,
                    "label": "医院",
                    "labelWidth": 200,
                    "value": null,
                    "type": "text",
                    "clearable": false,
                    "disabled": false,
                    "prefixIcon": null,
                    "suffixIcon": null,
                    "placeholder": "请填写医院名称",
                    "requireMsg": null,
                    "require": false,
                    "reg": null,
                    "regMsg": null
                  }
                },
                {
                  "name": "Inpute",
                  "type": "inputWork",
                  "label": "单行文本",
                  "icon": "account-book",
                  "sort": 4,
                  "options": {
                    "typeof": "input",
                    "col": 24,
                    "label": "科室",
                    "labelWidth": 200,
                    "value": null,
                    "type": "text",
                    "clearable": false,
                    "disabled": false,
                    "prefixIcon": null,
                    "suffixIcon": null,
                    "placeholder": "请填写所在科室",
                    "requireMsg": null,
                    "require": false,
                    "reg": null,
                    "regMsg": null
                  }
                },
                {
                  "name": "Selecte",
                  "type": "selectWork",
                  "label": "下拉框",
                  "icon": "deployment-unit",
                  "sort": 5,
                  "options": {
                    "typeof": "select",
                    "col": 24,
                    "label": "职称",
                    "labelWidth": 200,
                    "multiple": false,
                    "clearable": false,
                    "filterable": false,
                    "disabled": false,
                    "placeholder": "请选择职称",
                    "optionsType": true,
                    "optionsArray": [
                      "主任医师",
                      "副主任医师",
                      "主治医师",
                      "住院医师",
                      "医师",
                      "其他"
                    ],
                    "optionsZd": null,
                    "requireMsg": null,
                    "require": false
                  }
                }
              ],
              "pageCss": {
                "page_css_margin": "0px 0px 0px 0px",
                "page_css_padding": "24px 24px 24px 24px",
                "page_css_interval": 5,
                "page_css_radius": 0,
                "page_css_bgc": "#ffffff",
                "textBgc": "rgba(255,255,255,1)",
                "myCss": null,
                "loginName": "签到",
                "loginBtn": "131"
              }
            }
          }
        }
      }
      this.openLoginForm = true
    },
    async getTabFn() {
      if (!this.meeting_id) {
        return
      }
      let data = await getTab({ meeting_id: this.meeting_id, live_id: this.$route.query.id })
      this.tabForm = data.payload
      this.changeVideoBgc()
    },
    goOutUrl(item) {
      return
      console.log(item)
      window.open(item.url)
    },
    async onSubmit() {
      this.form.begin_time = this.moment(this.begin_time).format('X')
      this.form.end_time = this.moment(this.end_time).format('X')
      let data = await createVideoList(this.form)
      this.$message.success('保存成功！')
      console.log(data)
    },
    resetForm() {
      this.$router.go(0)
    },
    uploadOk(str) {
      if (str.status == 1) {
        this.form.cover_path = str.payload.path
        this.form.cover = str.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    input(str) {
      this.Editor.data.richText = str
      console.log(this.Editor)
    },
    changeListNum(index, item) {
      if (item.type == '2') {
        window.open(item.url)
        return
      }
      this.listNum = index
    },
    delateDateLogin(data) {
      this.form.sign_in_pic = ''
      this.form.sign_in_pic_path = ''
    },
    uploadOkLogin(data) {
      this.form.sign_in_pic = data.payload.id
      this.form.sign_in_pic_path = data.payload.path
      console.log(data)
    },
    changeChannel() {
      this.$forceUpdate()
    },
    changeVideoBgc() {
      if (this.form.video_background_path) {
        console.log(document.querySelectorAll(".lw_video_bg"))
        document.querySelectorAll(".lw_video_bg")[0].style.background = 'url(' + this.form.video_background_path + ')';
      } else {
        document.querySelectorAll(".lw_video_bg")[0].style.backgroundColor = '#ffffff'
      }
      if (this.meeting_data.background_color) {
        document.querySelectorAll("model")[0].style.backgroundColor = this.meeting_data.background_color
        document.querySelectorAll("model")[0].style.backgroundColor = this.meeting_data.background_color
      }
    }
  },
}
</script>

<style lang="less" scoped>
.video_mp4 {
  padding: 12px;
  background-color: #fff;
  border-radius: 6px;
  margin: 12px;
  .no_tab {
    margin-top: 12px;
    border-radius: 6px;
    overflow: hidden;
  }
  .video_poster {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    .no_imgPoster {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .video_title {
    margin-top: 12px;
    border: 1px solid #e8e8e8;
  }
  .video_message {
    padding: 0;
  }
}
:deep(.ant-form-item-label) {
  padding-right: 24px;
}
.showBox {
  font-size: 14px;
}
.channleList {
  overflow: auto;
  height: 80px;
  white-space: nowrap;
  background: #f8f8f8;
  padding: 8px;
  .channleListItem {
    width: 112px;
    display: inline-block;
    height: 100%;
    margin-right: 8px;
    border-radius: 4px;
    position: relative;
    .channleListItem_cover {
      border-radius: 4px;
      display: flex;
      font-weight: 400;
      font-size: 11px;
      color: #ffffff;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      padding: 3px;
      img {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }
      .text {
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
    .channleListItem_coverMY_icon {
      position: absolute;
      width: 12px;
      top: -11px;
      left: 0;
      right: 0;
      margin: auto;
      img {
        width: 12px;
      }
    }
    .channleListItem_coverMY {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
      );
    }
    .channleListItem_cover_img {
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }
  }
  .channleListItem:last-child {
    margin-right: 0;
  }
}
::-webkit-scrollbar {
  display: none;
  width: 10px;
  height: 10px;
}
* ::-webkit-scrollbar {
  display: none;
  width: 10px;
  height: 10px;
}
.send_box_video {
  position: fixed;
  bottom: 10px;
  width: calc(100% - 204px);
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  left: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listCover_img {
  width: 280px;
  height: 158px;
  overflow: hidden;
}
.lineBtn {
  width: 256px;
  height: 40px;
  button {
    color: #222222;
    height: 40px;
    font-size: 14px;
    margin-left: 0 !important;
  }

  .check2 {
    border: 1px solid #1890ff !important;
    color: #1890ff;
  }
}
.atstion {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  color: #86909d;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  margin-left: 12px;
}
.number_btn_home {
  display: flex;
  width: 160px;
  .number_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f7fa;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #e5e6eb;
    width: 40px;
    height: 40px;
  }
  .number_inp {
    flex: 1;
    border-radius: 0px;
    text-align: center;
  }
  .number_btn:last-child {
    border-radius: 0px 4px 4px 0px;
  }
}
.video_list_img {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  color: #86909d;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  .video_list_imgUp {
    width: 160px;
    height: 90px;
  }
  .video_list_imgP {
    flex: 1;
    margin-left: 12px;
    p {
      display: flex;
      align-items: center;
      img {
        margin: 4px;
      }
    }
    p:first-child {
      font-size: 13px;
      margin-bottom: 8px;
    }
  }
}
:deep(.ant-form-item-control) {
  max-width: 500px;
}
.dchang {
  position: fixed;
  left: 0;
  right: 0;
  top: 100px;
  margin: auto;
  width: 800px;
  height: 500px;
  border-radius: 4px 4px 0 0;
}
.dchang2 {
  top: 0;
  bottom: 0;
  width: unset;
  height: unset;
  background-color: rgba(0, 0, 0, 0.45);
}
.dchang_title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  word-wrap: break-word;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
}
.dchang_btn {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #ffffff;
  height: 54px;
  border-radius: 0 0 4px 4px;
  padding: 0px 24px;
}
.dataBox *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  /**/
}
.dataBox *::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.dataBox *::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.dataBox *::-webkit-scrollbar-thumb:hover {
  background: #333;
}
.dataBox *::-webkit-scrollbar-corner {
  background: #179a16;
}
.page {
  padding: 0 24px;
  display: flex;
  height: calc(100vh - 224px);
  padding-right: 0;
}
.page_l {
  position: relative;
  margin-top: 24px;
  // flex: 1;
  // background-color: #f7f9fc;
  display: flex;
  justify-content: center;
  overflow: auto;
  .model {
    position: relative;
    // margin-top: 20px;
    width: 375px;
    max-height: 802px;
    height: calc(100% - 24px);
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e5e6eb;
    margin-bottom: 24px;
  }
}
.loginForm {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  .loginFormBase {
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 303px;
    max-height: 70%;
    overflow: auto;
    min-height: 302px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
    bottom: 0;
    background: #fff;
    border-radius: 4px;
    margin: auto;
  }
  .video_baseFormBox {
    padding: 10px 24px;
  }
  .login_L_title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #222222;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    text-align: center;
    margin: 24px;
  }
  .login_L_send {
    width: 100%;
    padding: 14px 24px 24px 24px;
    display: flex;
    button {
      height: 46px;
      flex: 1;
      border-radius: 23px;
    }
  }
}
.video_time {
  display: flex;
  .ant-calendar-picker {
    line-height: unset;
  }
}
.page_c {
  margin-top: 24px;
  overflow: auto;
  flex: 1;
  form {
    margin-right: 24px;
  }
}
.page_join {
  flex: 1;
}
.page_r {
  width: 200px;
  background-color: aqua;
  height: 100%;
}
.video_poster {
  width: 100%;
  height: 198px;
  background-color: #333;
}
.video_title {
  height: 40px;
  background-color: white;
  border-bottom: solid 1px #eaebef;
  display: flex;
  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
      position: absolute;
      width: 2em;
      height: 2px;
      background-color: #108ee9;
      bottom: 0;
      border-radius: 2px;
    }
  }
}
.p12 {
  padding: 12px;
}
.video_message {
  font-family: PingFangSC-Medium, PingFang SC;
  // font-size: 0px;
  // border-bottom: solid 1px #eaebef;

  .img_span {
    width: 16px;
    height: 16px;
    display: flex;
    margin-right: 4px;
    flex-direction: column;
    justify-content: center;
  }
  .video_name {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px;
    position: relative;
  }
  .textBox {
    position: relative;
    margin-bottom: 12px;
  }
  .is_sign_in {
    position: absolute;
    right: 0;
    height: 100%;
    min-height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    img {
      width: 72px;
    }
  }
  .video_css {
    font-size: 13px;
    display: flex;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 3px;
    color: #868686;
  }
  .haibao {
    // margin-top: 12px;
    width: 100%;
    img {
      width: unset !important;
      height: unset !important;
      width: 100%;
      width: 100%;
    }
  }
}
:deep(.richText img) {
  width: unset !important;
  height: unset !important;
  width: 100% !important;
}
:deep(.haibao img) {
  width: unset !important;
  height: unset !important;
  width: 100% !important;
}
.video_haibao {
  padding: 12px;
  padding-top: 0;
  img {
    width: 100%;
  }
}
.uploadBox {
  width: 100px;
  height: 105px;
}
.img_row {
  font-size: 13px;
  display: flex;
  line-height: 16px;
  font-weight: 400;
  margin: 5px 0;
  color: #ccc;
}
.menuBox {
  display: flex;
  flex-wrap: wrap;
  .menu_item {
    background-color: #fff;
    margin-right: 10px;
  }
  .menu_item:last-child {
    // margin-right: 0;
  }
}
.item_add .ant-btn {
  width: 80px;
  margin-right: 0;
}
.bgf7f9fc {
  background-color: #f7f9fc;
}
.editorBox :deep(.ant-modal) {
  min-width: 650px !important;
  .editor {
    height: 400px;
  }
}
.upload_Box :deep(.ant-modal) {
  min-width: 450px !important;
}

.upload_Box :deep(.ant-upload-picture-card-wrapper) {
  display: block;
  min-height: 250px;
  .ant-upload {
    min-height: 250px;
  }
}

.uploadBox2 {
  width: 100%;
  // height: 250px;
  min-height: 250px;
}

.no_imgPoster {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imgPoster {
  width: 100%;
  height: 100%;
}
.message,
.no_message {
  width: 100%;
}
.no_message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  padding-top: 30px;
  img {
    width: 160px;
  }
}
.addMenu {
  width: 320px;
  background-color: #fff;
  .iconfont {
    font-size: 24px;
  }
}
.addMenu_top,
.addMenu_bot {
  div {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 70px;
      min-height: 70px;
      padding: 5px;
      background-color: #f7f9fc;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    span:last-child {
      margin-right: 0;
    }
    span:hover {
      background-color: #ccc;
    }
  }
}
.message {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 4px;
  color: #8d8d8d;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
  span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #555555;
    line-height: 18px;
    text-align: left;
    font-style: normal;
  }
  span:last-child {
    flex: 1;
  }
}
i {
  vertical-align: initial;
}
.img_login {
  width: 90px;
}
</style>