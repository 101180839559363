<template>
  <div v-if="options&&options.typeof=='日期时间选择器'" class="video_baseFormBox">
    <div class="baseFormBox_label"><span class="require" v-if="options.require"
        :class="{requireMsg :options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-date-picker v-model="options.value" :disabled="options.disabled" @change="changeTime"
        :format="options.valueType" :value-format="options.valueType" :placeholder="options.placeholder">
      </a-date-picker>
      <!-- <a-date-picker v-model="options.value" :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
        :disabled="options.disabled" @change="changeTime" v-show="rangeSelect" :type="rangeSelect"
        :placeholder="options.placeholder">
      </a-date-picker> -->
    </div>
    <slot></slot>
  </div>
</template>

<script>
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      keyValue: 0,
      oneSelect: "date",
      rangeSelect: "date"
    };
  },
  props: ['options', "index"],
  methods: {
    moment,
    changeTime(newval) {
      console.log(newval);
    }
  },

};
</script> 
<style>
.video_baseFormBox .ant-calendar-picker {
  width: 100%;
}
</style>