<template>
  <div class="video_baseFormBox">
    <div  class="baseFormBox_label"><span class="require"
        v-if="options.require"
        :class="{requireMsg :options.value}">*</span>{{options.label}}2345678765432</div>
    <div class="baseFormBox_data">
      <el-select v-model="form.value" :maxlength="options.multiple" :disabled="options.disabled"
        :clearable="options.clearable" :filterable="options.filterable" :placeholder="options.placeholder"
        :multiple="options.multiple">
        <el-option v-for="item in options.optionsArray" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <slot></slot>
  </div>
</template>

<script>  
export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  }, 
  props: ['options', "index"],
  methods: { 
  }, 
};
</script> 