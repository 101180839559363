<template>
  <div v-if="options&&options.typeof=='开关'" class="video_baseFormBox">
    <div  class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-switch v-model="options.value" :width="options.width"
        :active-icon-class="options.activeIconClass" :inactive-icon-class="options.inactiveIconClass"
        :active-value="options.activeValue" :inactive-value="options.inactiveValue" :active-color="options.activeColor"
        :inactive-color="options.inactiveColor" :active-text="options.activeText" :inactive-text="options.inactiveText"
        :name="options.name" :disabled="options.disabled" :validate-event="options.validateEvent">
      </a-switch>
    </div>
    <slot></slot>
  </div>
</template>

<script> 
export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  }, 
  props: ['options', "index"],
  methods: { 
  }, 
};
</script> 