<template>
  <div class="channel">
    <div class="channel_title pb20">
      <span>启用多频道</span>
      <span class="channel_title_at">开启后在直播详情页显示多频道列表</span>
      <a-switch :checked='channelData.channel_status==1' @change="onChange" />
    </div>

    <div v-if="channelData.channel_status==1">
      <div class="channel_title channel_list_box">
        <span>多频道内容</span>
        <div class="set_video pointer">
          <span @click='getVideoList' class="choiceImg_base_Noimg">
            <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
            选择直播
          </span>
        </div>
      </div>

      <div class="channel_title channel_list_box pb20">
        <span class="pb16">排序方式</span>
        <a-radio-group name="radioGroup" v-model="channelData.sort_type">
          <a-radio :value="1">
            自动排序
          </a-radio>
          <a-radio :value="2">
            手动排序
          </a-radio>
        </a-radio-group>
      </div>

      <div class="channel_title channel_list_title">
        <span>已选直播</span>
        <span class="channel_title_at">简称仅在多频道中显示</span>
      </div>

      <div class="channel_list">

        <draggable class="draggableBox" animation="1000" @end="endEventFN" v-model="channelData.channel_list">
          <div v-for="(item,index) in channelData.channel_list" :key="item.live_id" class="channel_list_item">
            <div v-if="item.live_id==videoID" class="channel_list_my">当前</div>
            <div class="list_title">{{item.title}}</div>
            <div v-if="item.channel_title" class="list_desc">频道名称：{{item.channel_title}}</div>
            <div class="list_message">
              <span class="list_message_l">{{item.begin_time|time('YYYY-MM-DD HH:mm')}}</span>
              <span class="list_message_c pointer" @click="showName(item,index)"><img src="@/assets/base_img/简称@2x.png"
                  alt="">编辑频道名称</span>
              <span v-if="item.live_id!=videoID" class="list_message_r pointer" @click="delateFn(index)"><img
                  src="@/assets/base_img/video_delate.png" alt=""></span>
            </div>
          </div>
        </draggable>

      </div>

      <div v-if="visible" class="add add_pof">
        <div class="add_form add_pof">
          <div class="add_form_title">
            编辑
            <a-icon @click="close" class="add_form_close" type="close" />
          </div>

          <div class="add_formBox">

            <div class="add_formBox_item">
              <div class="add_formBox_name">频道名称</div>
              <div class="add_formBox_inp">
                <a-input v-model="choice.channel_title" placeholder="请输入" :maxLength='10'
                  :suffix="choice.channel_title?choice.channel_title.length+'/10':'0/10'" />
              </div>
            </div>

          </div>

          <div class="add_formBox_sendBox">
            <a-button @click="close" class="add_formBox_sendBox_qx">取消</a-button>
            <a-button @click="handleOk" type="primary">
              确定
            </a-button>
          </div>

        </div>
      </div>
    </div>
    <div class="send_box_video">
      <a-button @click="onSubmit">
        返回
      </a-button>
      <a-button type="primary" style="margin-left: 10px;" @click="sendForm">
        保存
      </a-button>
    </div>

    <setVideo ref="setVideo" @giveVideo='giveVideo'></setVideo>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { channelCreate, channelGet } from '@/api/meetApi.js';
import setVideo from '@/views/workPlace/components/setVideo.vue'
export default {
  props: ['meetIng_id', 'videoForm', 'channelData'],
  components: { setVideo, draggable },
  data() {
    return {
      videoID: this.$route.query.id,
      visible: false,
      channel: {
        sort: 1
      },
      choiceIndex: '',
      choice: {
        title: ''
      },
      videoList: [],
      // channelData: {
      //   channel_list: [],
      //   channel_status: 2,
      //   sort_type: 1
      // }
    }
  },
  created() {
    this.channelData = this.channelData
    // this.getChannelGet();
  },
  methods: {
    endEventFN(argument) {
    },
    async getChannelGet() {
      let data = await channelGet({ meeting_id: this.meetIng_id, live_id: this.$route.query.id });
      this.channelData = data.payload;
      console.log(this.form)
      console.log(data)
    },
    async sendForm() {
      this.channelData.meeting_id = this.meetIng_id
      this.channelData.live_id = this.$route.query.id
      let data = await channelCreate(this.channelData);
      this.$message.success('保存成功');
    },
    getVideoList() {
      // 1普通直播，2，视频，3议程 4外链
      console.log(this.channelData.channel_list)
      this.$refs.setVideo.showModal(this.channelData.channel_list, 1);
    },
    giveVideo(data) {
      this.channelData.channel_list = data;
      let arr = [...this.channelData.channel_list, ...data];
      let arrA = data.reduce((acc, current) => {
        // 检查是否已经存在相同 id 且有 id 的对象
        const existingItem = acc.find(item => item.live_id === current.live_id && item.id !== undefined);
        // 如果当前对象的 id 有效，或者之前不存在相同 id 的对象，则加入结果数组
        if (current.id !== undefined || !existingItem) {
          acc.push(current);
        }
        return acc;
      }, []);
      // console.log(arrA)
    },
    delateFn(index) {
      if (this.channelData.channel_list[index].live_id == this.videoID) {
        return this.$message.error('当前直播不能删除')
      }
      this.channelData.channel_list.splice(index, 1);
    },
    onChange(checked) {
      if (checked) {
        let videoMsg = JSON.parse(JSON.stringify(this.videoForm))
        videoMsg.live_id = videoMsg.id
        // videoMsg.id = null
        this.channelData.channel_list = [videoMsg]
        console.log(this.channelData.channel_list)
        this.channelData.channel_status = 1;
      } else {
        this.channelData.channel_status = 2;
      }
    },
    close() {
      this.visible = false;
    },
    showName(item, index) {
      this.choiceIndex = index;
      this.choice = JSON.parse(JSON.stringify(item));
      this.visible = true;
    },
    handleOk() {
      this.channelData.channel_list[this.choiceIndex] = this.choice;
      this.visible = false; 
      this.$emit('changeChannel')
    },
    onSubmit() {
      window.history.go(-1);
    }
  }
}
</script>

<style lang="less" scoped>
.send_box_video {
  position: fixed;
  bottom: 10px;
  width: calc(100% - 204px);
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  left: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.channel {
  margin-left: 40px;
  max-width: 560px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb16 {
  padding-bottom: 16px;
}
.channel_title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff0f2;
  //   padding-bottom: 24px;
  .channel_title_at {
    font-weight: 400;
    font-size: 13px;
    color: #86909d;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    flex: 1;
    margin-left: 12px;
  }
}

.channel_list_title {
  padding-bottom: 16px;
  border-bottom: 0px solid #000;
}
.channel_list_box {
  border-bottom: 0px solid #000;
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
}
.set_video {
  width: 160px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #e5e6eb;
  margin-top: 20px;
}
.choiceImg_base_Noimg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #86909d;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  img {
    margin-right: 4px;
  }
}
.channel_list_item {
  .channel_list_my {
    position: absolute;
    text-align: center;
    left: 0;
    top: 0;
    width: 30px;
    height: 14px;
    background: rgba(24, 144, 255, 0.1);
    border-radius: 0px 0px 2px 0px;
    font-weight: 400;
    font-size: 10px;
    color: #1890ff;
    line-height: 14px;
    font-style: normal;
  }
  min-height: 76px;
  background: #f9fafc;
  border-radius: 4px;
  padding: 16px;
  position: relative;
  margin-bottom: 12px;
  .list_title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
    text-align: left;
    font-style: normal;
  }
  .list_desc {
    margin-top: 7px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #7f7f7f;
    line-height: 16px;
    text-align: left;
    font-style: normal;
  }
  .list_message {
    margin-top: 7px;
    display: flex;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #7f7f7f;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    .list_message_c {
      color: #1890ff;
      flex: 1;
      img {
        width: 12px;
        margin-right: 4px;
        margin-left: 12px;
      }
    }
    .list_message_r {
      img {
        width: 16px;
      }
    }
  }
}

.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 500px;
  height: 198px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: 0;
}
.add_form_title {
  display: flex;
  justify-content: space-between;
  height: 46px;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    cursor: pointer;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  padding: 20px;
  padding-bottom: 0;
}
.add_formBox_name {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.add_formBox_require {
  color: red;
  margin-right: 5px;
}
.add_formBox_item {
  margin-bottom: 20px;
  display: flex;
}
.add_formBox_sendBox {
  flex: 1;
  border-top: 1px solid #e3e4e8;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 20px;
}
.add_formBox_sendBox_qx {
  margin-right: 10px;
}
.add_formBox_time {
  width: 100%;
}
.add_formBox_inp {
  flex: 1;
  position: relative;
  .iconfont {
    position: absolute;
    color: #d9d9d9;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
}

.add_form_close:hover {
  color: #000;
  font-weight: 600;
}

.add_formBox_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.add_formBox_radio {
  position: relative;
  right: -16px;
}
</style>

<style>
.add_formBox_time .ant-calendar-picker-input {
  padding-left: 38px;
}
.add_formBox_time .anticon {
  display: none;
}
</style>